import { Modal, Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Screen, Select } from '../../components'
import { useCallback, useMemo, useState } from 'react'
import { TopicsModal } from './components/TopicsModal'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTopics, useRemoveTopic, useShareTopic } from '../../services/topic'
import { type ITopic } from '../../types/topic'
import { useGetQuestionsInfo } from '../../services/question/utils'
import { useMassiveAlloweance } from '../../services/userAlloweance'
import { useGetBranches } from '../../services/branch'
import { parseSelect } from '../../utils/parseSelect'
import { useBranch } from '../../hooks/useBranch'

const AdminTopics = () => {
  const { branch } = useBranch()
  const { branches, loading: loadingBranches } = useGetBranches()
  const { subjects } = useGetSubjectsByBranch()
  const { topics, loading, refetch } = useGetTopics()

  const { removeTopic, loading: removeLoading } = useRemoveTopic()
  const { questionsInfo, loading: loadingInfo } = useGetQuestionsInfo()
  const { massiveAlloweance, loading: loadingMassive } = useMassiveAlloweance()
  const { shareTopic, loading: sharing } = useShareTopic()

  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [massiveModal, setMassiveModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState<ITopic | undefined>()
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>()

  const toggleShareModal = useCallback(() => { setShareModal((prev) => !prev) }, [])
  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleMassiveModal = useCallback(() => { setMassiveModal((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const parsedBranches = useMemo(() => parseSelect({
    array: (branches || []).filter((b) => b._id !== branch?._id),
    labelKey: 'name',
    valueKey: '_id'
  }), [branches])

  const onClose = async () => {
    setSelectedTopic(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedTopic(undefined)
    toggleRemoveModal()
  }
  const onCloseMassive = () => {
    setSelectedTopic(undefined)
    toggleMassiveModal()
  }
  const onCloseShare = () => {
    setSelectedTopic(undefined)
    toggleShareModal()
  }

  const onEditTopic = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleModal()
  }

  const onMassive = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleMassiveModal()
  }

  const onShare = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleShareModal()
  }

  const onRemoveModal = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedTopic?._id) {
      await removeTopic(selectedTopic?._id)
      await onClose()
    }
  }

  const handleMassive = async () => {
    if (selectedTopic?._id) {
      await massiveAlloweance(selectedTopic?._id)
      toggleMassiveModal()
    }
  }

  const handleShare = async () => {
    if (selectedTopic?._id && selectedBranch) {
      await shareTopic({
        topic: selectedTopic?._id,
        branch: selectedBranch
      })
      toggleShareModal()
    }
  }

  const columns = getColumns({
    onEdit: onEditTopic,
    onRemove: onRemoveModal,
    onMassive,
    onShare,
    info: questionsInfo
  })

  return (
    <Screen title='Temas' id="topics" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
      {(loading || loadingInfo) && <Spin tip="Loading" size="large" />}
      {!(loading || loadingInfo) && (
        <Table locale={{ emptyText: 'No hay temas que mostrar' }} columns={columns} dataSource={topics} rowKey="_id" />
      )}
      <TopicsModal
        selectedTopic={selectedTopic}
        subjects={subjects ?? []}
        open={modal}
        onClose={onClose}
        title={`${selectedTopic ? 'Actualizar' : 'Crear'} Tema`}
      />
      <Modal
        open={removeModal}
        onCancel={onCloseRemove}
        okButtonProps={{
          danger: true,
          loading: removeLoading
        }}
        onOk={handleRemove}
        okText="Eliminar tema"
        title="Eliminar tema"
      >
        {`Vas a eliminar el tema "${selectedTopic?.name}". ¿Estas seguro de que quieres continuar? No podrás acceder al tema, ni a sus preguntas, etc...`}
      </Modal>
      <Modal
        open={massiveModal}
        onCancel={onCloseMassive}
        okButtonProps={{
          loading: loadingMassive
        }}
        onOk={handleMassive}
        okText="Continuar"
        title="Matriculación massiva"
      >
        {`Vas a matricular a todos los usuarios al tema "${selectedTopic?.name}". ¿Estas seguro de que quieres continuar?`}
      </Modal>
      <Modal
        open={shareModal}
        onCancel={onCloseShare}
        okButtonProps={{
          loading: sharing
        }}
        onOk={handleShare}
        okText="Continuar"
        title="Compatir tema"
      >
        {`Selecciona la rama con la que quieres compartir "${selectedTopic?.name}".`}
        <Select
          loading={loadingBranches}
          placeholder="Rama"
          onChange={setSelectedBranch}
          options={parsedBranches}
          value={selectedBranch}
        />
      </Modal>
    </Screen>
  )
}

export { AdminTopics }
