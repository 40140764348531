import { gql } from '@apollo/client'

export const CREATE_TOPIC = gql`
mutation createTopic ($input: TopicInput!) {
    createTopic(input:$input) {
        _id
        active
        color
        name
    }
  }
`

export const UPDATE_TOPIC = gql`
mutation updateTopic ($input: UpdateTopicInput!) {
    updateTopic(input:$input) {
        _id
        active
        color
        name
    }
  }
`

export const REMOVE_TOPIC = gql`
mutation removeTopic ($input: String!) {
    removeTopic(input:$input) {
        name
        active
        color
        _id
    }
}
`

export const SHARE_TOPIC = gql`
    mutation shareWithBranch($input: ShareTopicInput!) {
        shareWithBranch(input: $input) {
            _id
            name
            active
            color
        }
    }
`
