import { useMutation, useQuery } from '@apollo/client'
import { type ITopic } from '../../types/topic'
import { GET_TOPICS, GET_TOPICS_BY_SUBJECT } from './queries'
import { CREATE_TOPIC, REMOVE_TOPIC, SHARE_TOPIC, UPDATE_TOPIC } from './mutations'
import { useBranch } from '../../hooks/useBranch'
import { useUser } from '../../hooks/useUser'
import { useGetUserAlloweance } from '../userAlloweance'

interface GetTopicsResponse {
  getTopicsByBranch: ITopic[]
}
export const useGetTopics = () => {
  const { isAdmin, user } = useUser()
  const { alloweance, loading: loadingAlloweance } = useGetUserAlloweance(user?._id)
  const { branch } = useBranch()
  const { data, loading, refetch } = useQuery<GetTopicsResponse>(GET_TOPICS, {
    variables: {
      input: branch?._id
    }
  })

  if (!isAdmin) {
    const topics = data?.getTopicsByBranch as ITopic[]
    const allowedTopicsIds = (alloweance?.topics as any)?.map((s: any) => s._id)
    const filteredTopics = (topics || []).filter(({ _id }) => allowedTopicsIds?.includes(_id!))

    return {
      topics: filteredTopics,
      loading: loadingAlloweance || loading,
      refetch
    }
  }

  return {
    topics: data?.getTopicsByBranch,
    loading,
    refetch
  }
}

export const useGetTopicsBySubject = (input?: string) => {
  const { isAdmin, user } = useUser()
  const { alloweance, loading: loadingAlloweance } = useGetUserAlloweance(user?._id)
  const { data, loading, refetch } = useQuery(GET_TOPICS_BY_SUBJECT, {
    fetchPolicy: 'no-cache',
    variables: { input },
    skip: !input
  })

  if (!isAdmin) {
    const topics = data?.getTopicsBySubject as ITopic[]
    const allowedTopicsIds = (alloweance?.topics as any)?.map((s: any) => s._id)
    const filteredTopics = (topics || []).filter(({ _id }) => allowedTopicsIds?.includes(_id!))

    return {
      topics: filteredTopics,
      loading: loadingAlloweance || loading,
      refetch
    }
  }

  return {
    topics: data?.getTopicsBySubject,
    loading,
    refetch
  }
}

export const useCreateTopic = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_TOPIC)

  const createTopic = async ({ color, name, subject }: ITopic) => {
    await action({
      variables: {
        input: {
          active: true,
          branch: branch?._id,
          color,
          executedBy: 'changeForUser',
          subject,
          name
        }
      }
    })
  }

  return { createTopic, loading }
}

export const useUpdateTopic = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(UPDATE_TOPIC)

  const updateTopic = async ({ _id, color, name, subject }: ITopic) => {
    await action({
      variables: {
        input: {
          _id,
          active: true,
          branch: branch?._id,
          color,
          executedBy: 'changeForUser',
          name,
          subject
        }
      }
    })
  }

  return { updateTopic, loading }
}

export const useRemoveTopic = () => {
  const [action, { loading }] = useMutation(REMOVE_TOPIC)

  const removeTopic = async (id: string) => {
    await action({
      variables: {
        input: id
      }
    })
  }

  return { removeTopic, loading }
}

interface ShareWithBranchInput {
  topic: string
  branch: string
}

export const useShareTopic = () => {
  const [action, { loading }] = useMutation(SHARE_TOPIC)

  const shareTopic = async (input: ShareWithBranchInput) => {
    await action({
      variables: {
        input
      }
    })
  }

  return { shareTopic, loading }
}
